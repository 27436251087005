/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background: #100d09;
    height: 100%;
    scroll-behavior: smooth
} */

body {
    background-color: #4e250a;
    background-image: url("./images/bg-orange.jpg");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-attachment: scroll;
    margin: 0;
    padding: 0;
    min-height: 100;
    color: #c7b5a3;
    font-size: 16px;
    font-weight: 400;
    font-family: "Inter","Roboto",sans-serif;
    line-height: 1.5
}

@media(max-width: 1200px) {
    body {
        background-size:auto 920px
    }
}